<script setup lang="ts">
import type { TransferListOptionsItem } from '@reports/components/TransferList/types'

interface Props {
  options: TransferListOptionsItem[]
}

const props = withDefaults(defineProps<Props>(), {
  options: () => [],
})

const emit = defineEmits(['update:options', 'unselect'])
const options = useVModel(props, 'options', emit)
</script>

<template>
  <ReportsTransferListColumn header="All fields">
    <!-- Header -->
    <template #header>
      <div class="px-4">
        <h2 class="inline-flex w-full justify-between text-base font-normal">
          <span>All Selected fields</span>
          <span>{{ options.length }}</span>
        </h2>
      </div>
    </template>
    <!-- List -->
    <ReportsTransferListBaseList
      v-model:options="options"
      draggable
      scroll-to-bottom
      class="h-full"
    >
      <template #item="{ option }">
        <ReportsTransferListItem
          :option="option"
          class="group-[.sortable-chosen]/item:bg-gray-750 group-data-[dragging=false]/list:hover:bg-gray-750 group-[.sortable-chosen]/item:text-white group-data-[dragging=false]/list:cursor-grab group-data-[dragging=false]/list:hover:text-white group-data-[dragging=false]/list:active:cursor-grabbing"
          :action="{ icon: 'trash', tooltip: 'Remove from Selected fields' }"
          action-class="text-red group-data-[dragging=true]/list:!hidden"
          @click-button="$emit('unselect', option)"
        >
          <div class="flex gap-2">
            <div class="flex group-hover/item:flex">
              <Icon name="dots-grid" class="self-center" />
            </div>
            <span class="text-sm">
              {{ option.label }}
            </span>
          </div>
        </ReportsTransferListItem>
      </template>
      <template #empty> Select the fields to export </template>
    </ReportsTransferListBaseList>
  </ReportsTransferListColumn>
</template>
